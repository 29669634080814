/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useCallback, useRef } from "react"
import { Helmet } from "react-helmet"

const redirect = () => (window.location.href = "https://www.mitigate.dev")

const Layout = () => {
  const containerRef = useRef()
  const lottieRef = useRef()
  const timerRef = useRef()

  const onFrame = useCallback(() => {
    if (timerRef.current) return

    containerRef.current.classList.add("animated")

    timerRef.current = setTimeout(redirect, 7500)
  }, [])

  useEffect(() => {
    lottieRef.current.addEventListener("frame", onFrame)
    lottieRef.current.addEventListener("complete", redirect)

    return () => {
      lottieRef.current.removeEventListener("frame", onFrame)
      lottieRef.current.removeEventListener("complete", redirect)

      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [onFrame])

  return (
    <>
      <Helmet>
        <script
          src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
          type="text/javascript"
        ></script>
      </Helmet>
      <main
        ref={containerRef}
        className="bg flex items-center justify-center h-[100vh] w-[100vw] overflow-hidden"
      >
        <lottie-player
          src="/redirect.json"
          background="transparent"
          speed="1"
          class="h-[45vmin]"
          autoplay
          ref={lottieRef}
        />
      </main>
    </>
  )
}

export default Layout
